import React from "react"
import { Button } from "../../../atoms/Button"
import { SearchIcon } from "../../../utils/IconsList"
import { Search, SearchWrapper, ButtonWrap, Wrap, InputWrap } from "../styles/Search.styled"

const SearchArea = ({ handleKey, isTablet, isScrolled, setOverlayOpen }) => {
  let color
  let padding
  let boxShadow

  if (isScrolled && isTablet) {
    color = "#fff"
    padding = "16px"
    boxShadow = "0px 20px 30px rgba(16, 24, 40, 0.05)"
  } else if (!isTablet) {
    color = "transparent"
    padding = "0px"
    boxShadow = "none"
  } else {
    color = "transparent"
    padding = "56px"
    boxShadow = "none"
  }

  return (
    <SearchWrapper style={{ background: color, paddingTop: padding, boxShadow: boxShadow }}>
      <Wrap>
        {isTablet &&
          <ButtonWrap onClick={() => setOverlayOpen(true)}>
            <Button ctaVariant='secondary'>Browse by category</Button>
          </ButtonWrap>
        }
        <InputWrap>
          <Search onChange={handleKey} type="text" placeholder="Search Integrations..." />
          <SearchIcon />
        </InputWrap>
      </Wrap>
    </SearchWrapper>
  )
}

export default SearchArea
