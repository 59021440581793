import React from "react"
import {
  SidebarHeading,
  SidebarSection,
  SidebarSubsection,
  SidebarFilterHead,
  SectionWrapper,
} from "../styles/Sidebar.styled"
import onKeyDown from "../../../utils/onKeyDown"
import { handleCheck } from "../../../utils/filterFunctions"

const Sidebar = ({
  categories,
  collections,
  departments,
  activeCategories,
  activeCollections,
  activeDepartments,
  setActiveCategories,
  setActiveCollections,
  setActiveDepartments,
  setCurrentPage,
  overlayOpen,
  isScrolled,
  scrollToTop,
}) => {
  const activeFilters = {
    categories: activeCategories.length === categories.length,
    departments: activeDepartments.length === departments.length,
    collections: activeCollections.length === collections.length,
  }

  const handleParentCheckbox = (type, isScrolled) => {
    switch (type || "") {
      case "Categories":
        setActiveCategories(!activeFilters.categories ? categories : [])
        setCurrentPage(1)
        isScrolled && scrollToTop()
        break
      case "Departments":
        setActiveDepartments(!activeFilters.departments ? departments : [])
        setCurrentPage(1)
        isScrolled && scrollToTop()
        break
      case "Collections":
        setActiveCollections(!activeFilters.collections ? collections : [])
        setCurrentPage(1)
        isScrolled && scrollToTop()
        break
      default:
        break
    }
  }

  return (
    <>
      <SidebarHeading>Browse by category</SidebarHeading>
      <SectionWrapper overlay={overlayOpen}>
        <SidebarSection id="categories">
          <SidebarFilterHead className={`select ${activeCategories.length === categories.length ? `active` : ``}`}>
            Categories
            <input
              tabIndex={-1}
              type="checkbox"
              name="Categories"
              value="Categories"
              checked={activeCategories.length === categories.length}
              aria-label={`${activeCategories.length === categories.length ? "Deselect" : "Select"} all Category Filters`}
              id={`checkbox-categories`}
              onClick={e => handleParentCheckbox(e.target.name, isScrolled)}
            />
            <span
              className="checkmark"
              role="checkbox"
              aria-label={`${activeCategories.length === categories.length ? "Deselect" : "Select"} all Category Filters`}
              aria-checked={activeCategories.length === categories.length}
              tabIndex={0}
              onKeyDown={(e) => onKeyDown(e, () => handleParentCheckbox("Categories", isScrolled))}
            />
          </SidebarFilterHead>
          <SidebarSubsection id="categories-list">
            {categories.map((category, index) => (
              <label className={`select ${activeCategories.includes(category) ? `active` : ``}`}>
                {category}
                <input
                  tabIndex={-1}
                  type="checkbox"
                  name={category}
                  value={category}
                  checked={activeCategories.includes(category)}
                  aria-label={`Select ${category} category filter`}
                  id={`checkbox-category-${index}`}
                  onClick={() =>
                    handleCheck(
                      category,
                      activeCategories,
                      setActiveCategories,
                      setCurrentPage,
                      isScrolled,
                      scrollToTop,
                    )
                  }
                />
                <span
                  className="checkmark"
                  role="checkbox"
                  tabIndex={0}
                  aria-label={`Select ${category} category filter`}
                  aria-checked={activeCategories.includes(category)}
                  onKeyDown={(e) => onKeyDown(e, () => handleCheck(
                    category,
                    activeCategories,
                    setActiveCategories,
                    setCurrentPage,
                    isScrolled,
                    scrollToTop,
                  ))}
                />
              </label>
            ))}
          </SidebarSubsection>
        </SidebarSection>
        {/* {collections.length > 0 && (
          <SidebarSection id="collections">
            <SidebarFilterHead className={`select ${activeCollections.length === collections.length ? `active` : ``}`}>
              Collections
              <input
                tabIndex={-1}
                type="checkbox"
                name="Collections"
                value="Collections"
                checked={activeCollections.length === collections.length}
                aria-label={`${activeCollections.length === collections.length ? "Deselect" : "Select"} all Collection filters`}
                id={`checkbox-collections`}
                onClick={e => handleParentCheckbox(e.target.name, isScrolled)}
              />
              <span
                className="checkmark"
                role="checkbox"
                tabIndex={0}
                onKeyDown={(e) => onKeyDown(e, () => handleParentCheckbox("Collections", isScrolled))}
                aria-label={`${activeCollections.length === collections.length ? "Deselect" : "Select"} all Collection filters`}
                aria-checked={activeCollections.length === collections.length}
              />
            </SidebarFilterHead>
            <SidebarSubsection id="collections-list">
              {collections.map((collection, index) => (
                <label className={`select ${activeCollections.includes(collection) ? `active` : ``}`}>
                  {collection}
                  <input
                    tabIndex={-1}
                    type="checkbox"
                    name={collection}
                    value={collection}
                    checked={activeCollections.includes(collection)}
                    aria-label={`Select ${collection} collection filter`}
                    id={`checkbox-collection-${index}`}
                    onClick={() =>
                      handleCheck(
                        collection,
                        activeCollections,
                        setActiveCollections,
                        setCurrentPage,
                        isScrolled,
                        scrollToTop,
                      )
                    }
                  />
                  <span
                    className="checkmark"
                    role="checkbox"
                    tabIndex={0}
                    aria-checked={activeCollections.includes(collection)}
                    aria-label={`Select ${collection} collection filter`}
                    onKeyDown={(e) => onKeyDown(e, () => handleCheck(
                      collection,
                      activeCollections,
                      setActiveCollections,
                      setCurrentPage,
                      isScrolled,
                      scrollToTop,
                    ))}
                  />
                </label>
              ))}
            </SidebarSubsection>
          </SidebarSection>
        )}
        {departments.length > 0 && (
          <SidebarSection id="departments">
            <SidebarFilterHead className={`select ${activeDepartments.length === departments.length ? `active` : ``}`}>
              Departments
              <input
                tabIndex={-1}
                type="checkbox"
                name="Departments"
                value="Departments"
                checked={activeDepartments.length === departments.length}
                id={`checkbox-departments`}
                onClick={e => handleParentCheckbox(e.target.name, isScrolled)}
                aria-label={`${activeDepartments.length === departments.length ? "Deselect" : "Select"} all Department filters`}
              />
              <span
                className="checkmark"
                role="checkbox"
                tabIndex={0}
                onKeyDown={(e) => onKeyDown(e, () => handleParentCheckbox("Departments", isScrolled))}
                aria-label={`${activeDepartments.length === departments.length ? "Deselect" : "Select"} all Department filters`}
                aria-checked={activeDepartments.length === departments.length}
              />
            </SidebarFilterHead>
            <SidebarSubsection id="departments-list">
              {departments.map((department, index) => (
                <label className={`select ${activeDepartments.includes(department) ? `active` : ``}`}>
                  {department}
                  <input
                    tabIndex={-1}
                    type="checkbox"
                    name={department}
                    value={department}
                    checked={activeDepartments.includes(department)}
                    id={`checkbox-department-${index}`}
                    aria-label={`Select ${department} department filter`}
                    onClick={() =>
                      handleCheck(
                        department,
                        activeDepartments,
                        setActiveDepartments,
                        setCurrentPage,
                        isScrolled,
                        scrollToTop,
                      )
                    }
                  />
                  <span
                    className="checkmark"
                    role="checkbox"
                    tabIndex={0}
                    aria-label={`Select ${department} department filter`}
                    aria-checked={activeDepartments.includes(department)}
                    onKeyDown={(e) => onKeyDown(e, () => handleCheck(
                      department,
                      activeDepartments,
                      setActiveDepartments,
                      setCurrentPage,
                      isScrolled,
                      scrollToTop,
                    ))}
                  />
                </label>
              ))}
            </SidebarSubsection>
          </SidebarSection>
        )} */}
      </SectionWrapper>
    </>
  )
}

export default Sidebar
