import React, { useState, useEffect, useLayoutEffect, useMemo, useCallback } from "react";
import { Link } from "gatsby";
import { Button } from "../../atoms/Button"
import { IntegrationsCard } from "../Card/IntegrationsCard"
import {
  ListingWrapper,
  CardDeckWrapper,
  ContentWrapper,
} from "./styles/IntegrationsCardDeck.styled";
import { getCardSlug } from "../../utils/getCardSlug";
import { paginator } from "../../utils/paginatorFunction";
import PopularApps from "./components/PopularApps";
import AppsListing from "./components/AppsListing";
import Sidebar from "./components/Sidebar";
import ContactUsBar from "./components/ContactUsBar";
import SearchArea from "./components/Search";

import { ClearIcon, Arrow } from "../../utils/IconsList";
import useScreenResponsive from "../../utils/screenResponsive";
import { pushParams } from "../../utils/pushParams"
import { useIsScrolled } from "../../hooks/useIsScrolled";
import { CardDeckSideBar, ClearButton, FilterOverlay, OverlayButtonWrap, OverlayWrap, SidebarCTA } from "./styles/Sidebar.styled";

import queryString from "query-string"

export const IntegrationsCardDeck = ({ posts, popular }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [maxPosts, setMaxPosts] = useState(16)
  const [activeKey, setActiveKey] = useState("")
  const [searching, setSearching] = useState(false)
  const [overlayOpen, setOverlayOpen] = useState(false)
  const [activeCategories, setActiveCategories] = useState([])
  const [activeDepartments, setActiveDepartments] = useState([])
  const [activeCollections, setActiveCollections] = useState([])
  const [viewAll, setViewAll] = useState(false)
  const [alphabetical, setAlphabetical] = useState(false)
  const [sorted, setSorted] = useState([])
  const [filterParams, setFilterParams] = useState("")

  const categories = []
  const departments = []
  const collections = []

  const isTablet = useScreenResponsive(992)
  const { isScrolled } = useIsScrolled(430);
  const { isScrolled: desktopIsScrolled } = useIsScrolled(900);

  useEffect(() => {
    if (posts.length > 0) {
      setSorted(posts)
    }
  }, [posts]);

  const scrollToTop = (num) => {
    window.scrollTo({
      top: num || 500,
      behavior: 'smooth'
    });
  };

  const updatePaginator = () => {
    if (window.innerWidth < 576) {
      setMaxPosts(viewAll ? 16 : 8);
    } else if (window.innerWidth < 992) {
      setMaxPosts(viewAll ? 20 : 12);
    } else {
      setMaxPosts(viewAll ? 24 : 16);
    }
  };

  const handleKey = e => {
    if (e.target.value.length) {
      setActiveKey(e.target.value)
    } else {
      setActiveKey("")
    }
  }

  const handleSelect = () => {
    setOverlayOpen(false)
    isScrolled && scrollToTop()
  }

  const handleClear = () => {
    setActiveCategories([])
    setActiveCollections([])
    setActiveDepartments([])
    setCurrentPage(1)
    overlayOpen && setOverlayOpen(false)
    isScrolled && scrollToTop()
  }

  const handleViewAll = () => {
    setViewAll(!viewAll)
    setAlphabetical(!alphabetical)
    handleClear()
  }

  useEffect(() => {
    const params = queryString.parse(window.location.search)

    const parseFilterParamArray = (paramName) => {
      if (params[paramName]?.length > 0) {
        if (Array.isArray(params[paramName])) {
          return params[paramName]
        } else {
          return [params[paramName]]
        }
      } else {
        return []
      }
    }

    const updatedFilters = {
      activeKey: params?.searchField || "",
      activeCategories: parseFilterParamArray("category"),
      activeCollections: parseFilterParamArray("collection"),
      activeDepartments: parseFilterParamArray("department"),
      alphabetical: params?.sort === 'alphabetical' || false,
    }

    setActiveKey(updatedFilters?.activeKey)
    setActiveCategories(updatedFilters?.activeCategories)
    setActiveCollections(updatedFilters?.activeCollections)
    setActiveDepartments(updatedFilters?.activeDepartments)
    setAlphabetical(updatedFilters?.alphabetical)
  }, [])

  const handleFilterParams = useCallback(() => {
    const params = []

    activeKey !== '' && params.push(`searchField=${activeKey}`)

    activeCategories.length > 0 &&
      activeCategories.forEach((category) => {
        params.push(`category=${category.replaceAll('&', '%26')}`)
      })

    activeCollections.length > 0 &&
      activeCollections.forEach((collection) => {
        params.push(`collection=${collection.replaceAll('&', '%26')}`)
      })

    activeDepartments.length > 0 &&
      activeDepartments.forEach((department) => {
        params.push(`department=${department.replaceAll('&', '%26')}`)
      })

    if (alphabetical === true) {
      params.push("sort=alphabetical")
    }

    const paramsString = params.length > 0 ? "?" + params.join("&") : ""

    setFilterParams(paramsString)
  }, [activeCategories, activeCollections, activeDepartments, alphabetical, activeKey])

  useEffect(() => {
    pushParams(filterParams)
  }, [filterParams])

  useLayoutEffect(() => {
    handleFilterParams()
  }, [handleFilterParams])

  useEffect(() => {
    updatePaginator();
    window.addEventListener("resize", updatePaginator);
    return () => window.removeEventListener("resize", updatePaginator)
  })

  useEffect(() => {
    activeKey !== "" && setSearching(true);
    activeKey === "" && setSearching(false);
  }, [activeKey])

  useEffect(() => {
    if (searching) {
      setActiveCategories([])
      setActiveCollections([])
      setActiveDepartments([])
    }
  }, [searching])

  useEffect(() => {
    alphabetical ? setSorted(posts.sort((a, b) => a.heading.localeCompare(b.heading))) : setSorted(posts.sort((a, b) => b.updated - a.updated))
  }, [alphabetical, posts])

  const filtered = useMemo(() =>
    sorted &&
      sorted.length > 0 ?
      sorted
      .sort((a, b) => viewAll ? a.heading.localeCompare(b.heading) : posts.sort((a, b) => b.updated - a.updated))
      .filter(
        (post) => {
          const noFilters = (activeCategories.length === 0 && activeCollections.length === 0 && activeDepartments.length === 0 && activeKey === "")
          if (noFilters) return true

          const searchFilter = (activeKey !== ""
            && post?.heading?.toLowerCase().includes(activeKey?.toLowerCase()))

          const categoriesFilter = activeCategories.length > 0
            && activeCategories.some((cat) => post?.categories.includes(cat))

          const collectionsFilter = activeCollections.length > 0
            && activeCollections.some((col) => post?.collections.includes(col))

          const departmentsFilter = activeDepartments.length > 0
            && activeDepartments.some((dep) => post?.departments.includes(dep))

          return (
            searchFilter ||
            (categoriesFilter ||
              collectionsFilter ||
              departmentsFilter)
          )
        }
      ) : [], [activeCategories, activeCollections, activeDepartments, activeKey, sorted])

  sorted?.map((post) => {
    let postCategories = [...post?.categories]
    let postDepartment
    let postCollections

    if (post?.departments !== null) {
      postDepartment = [...post?.departments]
    }
    if (post?.collections !== null) {
      postCollections = [...post?.collections]
    }

    postCategories?.map(postCat => {
      if (!categories.includes(postCat)) {
        if (postCat !== "Integrations" && postCat !== "New" && postCat !== "Popular") {
          categories.push(postCat)
        }
      }
    })
    postDepartment && postDepartment?.map(postDep => {
      if (!departments.includes(postDep)) {
        departments.push(postDep)
      }
    })
    postCollections && postCollections?.map(postCol => {
      if (!collections.includes(postCol)) {
        collections.push(postCol)
      }
    })
  })

  categories.sort()
  departments.sort()
  collections.sort()

  let paginatedPostData = paginator(filtered, currentPage, maxPosts);

  let paginatedPosts = useMemo(() => paginatedPostData.data.map(integration => {
    let integrationSlug = getCardSlug(integration)

    return (
      <IntegrationsCard
        gatsbyImage={integration?.icon?.gatsbyImageData}
        title={integration?.heading}
        description={integration?.description?.description}
        link={integrationSlug}
        featuredImg={integration?.icon?.file?.url}
        categories={integration?.categories}
      />
    )
  }), [paginatedPostData])

  return (
    <ListingWrapper>
      {isTablet && <SearchArea overlayOpen={overlayOpen} setOverlayOpen={setOverlayOpen} isScrolled={isScrolled} handleKey={handleKey} isTablet={isTablet} />}
      <CardDeckWrapper>
        {!isTablet &&
          <CardDeckSideBar>
            {!isTablet && <SearchArea isScrolled={isScrolled} handleKey={handleKey} isTablet={isTablet} />}
            {filtered.length > 0 &&
              <>
                <Sidebar
                  isScrolled={desktopIsScrolled}
                  scrollToTop={scrollToTop}
                  categories={categories}
                  departments={departments}
                  collections={collections}
                  activeCategories={activeCategories}
                  activeCollections={activeCollections}
                  activeDepartments={activeDepartments}
                  setActiveCategories={setActiveCategories}
                  setActiveCollections={setActiveCollections}
                  setActiveDepartments={setActiveDepartments}
                  handleClear={handleClear}
                  setCurrentPage={setCurrentPage}
                />
                {(activeCategories.length > 0 || activeCollections.length > 0 || activeDepartments.length) > 0 &&
                  <ClearButton onClick={() => handleClear()}>
                    <ClearIcon /> Clear all filters
                  </ClearButton>
                }
              </>
            }
            <SidebarCTA>
              Want to build your own app?
              <Link to="/platform/no-code-app-builder">Learn more <Arrow /></Link>
            </SidebarCTA>
            <SidebarCTA>
              Request an integration
              <Link to="/about/contact">Contact us <Arrow /></Link>
            </SidebarCTA>
          </CardDeckSideBar>
        }
        <ContentWrapper>
          {(!searching &&
            !activeCategories.length &&
            !activeCollections.length &&
            !activeDepartments.length &&
            currentPage === 1 &&
            viewAll === false
          ) &&
            <PopularApps popular={popular} />
          }
          <AppsListing
            posts={filtered}
            maxPosts={maxPosts}
            searching={searching}
            paginatedPosts={paginatedPosts}
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            viewAll={viewAll}
            handleViewAll={handleViewAll}
            scrollToTop={scrollToTop}
            isTablet={isTablet}
          />
          <ContactUsBar />
        </ContentWrapper>
      </CardDeckWrapper>
      {isTablet && overlayOpen &&
        <FilterOverlay>
          <OverlayWrap>
            <Sidebar
              overlayOpen={overlayOpen}
              setOverlayOpen={setOverlayOpen}
              categories={categories}
              departments={departments}
              collections={collections}
              activeCategories={activeCategories}
              activeCollections={activeCollections}
              activeDepartments={activeDepartments}
              setActiveCategories={setActiveCategories}
              setActiveCollections={setActiveCollections}
              setActiveDepartments={setActiveDepartments}
              handleClear={handleClear}
              setCurrentPage={setCurrentPage}
            />
            <OverlayButtonWrap>
              <div id="select" onClick={() => handleSelect()}>
                <Button ctaVariant="primary">Select Categories</Button>
              </div>
              <div id="clear" onClick={() => handleClear()}>
                <Button ctaVariant="secondary">Clear Selection</Button>
              </div>
            </OverlayButtonWrap>
          </OverlayWrap>
        </FilterOverlay>
      }
    </ListingWrapper>
  )
}
