import React from "react"
import { PopularAppsSection, HeadingWrapper, SectionHeading, PopularGrid } from "../styles/IntegrationsCardDeck.styled"
import { IntegrationsCard } from "../../Card/IntegrationsCard"
import { getCardSlug } from "../../../utils/getCardSlug"

const PopularApps = ({ popular }) => {

  return (
    <PopularAppsSection>
      <HeadingWrapper>
        <SectionHeading>
          Popular Integrations
        </SectionHeading>
      </HeadingWrapper>
      <PopularGrid>
        {popular && popular.map((integration, index) => {
          let integrationSlug = getCardSlug(integration)

          return (
            <IntegrationsCard
              className="popular"
              key={integration?.id}
              gatsbyImage={integration?.icon?.gatsbyImageData}
              title={integration?.heading}
              description={integration?.description?.description}
              link={integrationSlug}
              featuredImg={integration?.icon?.file?.url}
              categories={integration?.categories}
            />
          )
        })}
      </PopularGrid>
    </PopularAppsSection>
  )
}

export default PopularApps
